<template>
    <el-dialog title="添加" :visible="showAddConfig" width="504px" @close="closeAdd" class="dialog-vertical">
        <el-form ref="configForm" :model="configForm" label-width="100px" :rules="rules" size="medium">
            <el-form-item label="模块名称" prop="name">
                <el-input v-model="configForm.name" placeholder="请填写模块名称"></el-input>
            </el-form-item>
            <el-form-item label="路径类型" prop="jump_type">
                <el-select v-model="configForm.jump_type" placeholder="请选择路径">
                    <el-option v-for="(item,index) in regionList" :label="item.name" :value="item.type"
                               :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="链接至" prop="jump_config.url">
                <el-input v-model="configForm.jump_config.url" placeholder="请填写链接"></el-input>
            </el-form-item>
            <el-form-item label="请上传图标" prop="icon">
                <SingleMediaWall v-model="configForm.icon" :width="100" :height="100"
                                 :borderRadius="6"></SingleMediaWall>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button size="small" @click="closeAdd">取消</el-button>
            <el-button size="small" type="primary" @click="handleAddConfig('configForm')">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
    import SingleMediaWall from "../../../common/components/SingleMediaWall";

    export default {
        name: "AddConfig",
        props: ['showAddConfig', 'selectIcon', 'editData'],
        data() {
            return {
                configForm: {
                    name: '',
                    icon: '',
                    jump_config: {
                        url: '',
                        path: '',
                    },
                    jump_type: '',
                    enable: 1
                },
                regionList: [
                    {name: '小程序路径', type: 'path'},
                    {name: 'H5 微网站路径', type: 'url'}
                ],
                rules: {
                    name: [{required: true, message: '请填写模块名称', trigger: 'blur'}],
                    jump_type: [{required: true, message: '请选择路径', trigger: 'blur'}],
                    icon: [{required: true, message: '请上传图片', trigger: 'blur'}],
                    'jump_config.url': [{required: true, message: '请填写链接', trigger: 'blur'}]
                }
            }
        },
        components: {
            SingleMediaWall
        },
        watch: {
            selectIcon() {
                if (this.selectIcon) {
                    this.configForm.icon = this.selectIcon
                }
            },
            editData() {
                if (this.editData.name) {
                    this.configForm = this.editData
                }
            },
            showAddConfig() {
                if (!this.showAddConfig) {
                    console.log(11)
                    this.configForm = {
                        name: '',
                        icon: '',
                        jump_config: {
                            url: '',
                            path: '',
                        },
                        jump_type: '',
                        enable: 1
                    }
                    // this.$refs['configForm'].resetFields();
                }
            }
        },
        methods: {
            handleShowSelectIcon() {
                this.$emit('showIconSelect')
            },
            closeAdd() {
                this.$emit('closeAddConfig')
            },
            handleAddConfig(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        const configObj = JSON.parse(JSON.stringify(this.configForm))
                        configObj.jump_config.path = configObj.jump_config.url
                        this.$emit('addConfigData', configObj)
                        this.closeAdd()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .dialog-bottom {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 20px;
        justify-content: center;
    }
</style>
