import api from "@/base/utils/request";

//获取首页设置
export const getCycIndexNavs = data => {
    return api({
        url: "/admin/cyc/Settings/getCycIndexNavsConfig",
        method: "post",
        data
    });
};
//保存首页设置
export const setCycIndexNavs = data => {
    return api({
        url: "/admin/cyc/Settings/setCycIndexNavsConfig",
        method: "post",
        data
    });
};