<template>
  <div class="content">
    <div class="install-top">
      <el-button size="small" type="primary" @click="handleShowAdd">新增</el-button>
    </div>
    <el-table v-loading="loading" style="width: 100%" class="thead-light" stripe :data="config_list"
      id="sortTable" row-key="id">
      <el-table-column prop="id" label="序号" :index="indexMethod" min-width="60">
      </el-table-column>
      <el-table-column label="图标" min-width="90">
        <template slot-scope="scope">
          <list-image :src="scope.row.icon"></list-image>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="模块名称" min-width="110"></el-table-column>
      <el-table-column prop="jump_config.url" label="链接至" min-width="200"></el-table-column>
      <el-table-column label="显示" min-width="60">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.enable" :true-label="1" :false-label="0"
            @change="handleChangeStatus(scope.$index)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="scope">
          <el-button style="color: #3576FF" size="small" type="text"
            @click="handleShowAdd(scope.row,scope.$index)">编辑</el-button>
          <el-button style="color: #3576FF" size="small" type="text" @click="handleDelete(scope.$index)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <AddConfig :showAddConfig="showAddConfig" :editData="editData" @closeAddConfig="closeAddConfig"
      @addConfigData="addConfigData"></AddConfig>
    <FixedActionBar>
      <el-button type="primary" @click="handleSaveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { getCycIndexNavs, setCycIndexNavs } from "../api/index-config";
import AddConfig from "../components/AddInstall/AddConfig";
import Sortable from "../js/Sortable.min";
import FixedActionBar from "../../../base/layout/FixedActionBar";
import ListImage from "../../../base/components/List/ListImage";
export default {
  name: "index-install",
  data() {
    return {
      config_list: [],
      loading: false,
      showAddConfig: false,
      editData: {},
      editIndex: -1,
    };
  },
  components: {
    ListImage,
    AddConfig,
    FixedActionBar,
  },
  created() {
    this.getIndexConfig();
  },
  mounted() {
    this.drag();
  },
  methods: {
    // 序号计算
    indexMethod(index) {
      return index + 1;
    },
    closeAddConfig() {
      this.editIndex = -1;
      this.editData = {};
      this.showAddConfig = false;
    },
    // 拖拽排序
    drag() {
      const el = document.querySelectorAll(
        "#sortTable .el-table__body > tbody"
      )[0];
      Sortable.create(el, {
        disabled: false,
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {},
        onEnd: (e) => {
          let arr = this.config_list; // 获取表数据
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理
          this.$nextTick(() => {
            this.config_list = [];
            arr.forEach((item, index) => {
              this.config_list.push(item);
            });
          });
        },
      });
    },
    addConfigData(data) {
      console.log(data);
      if (this.editIndex >= 0) {
        this.config_list.splice(this.editIndex, 1, data);
        console.log(this.config_list);
        this.editIndex = -1;
        this.editData = {};
      } else {
        data.id = this.config_list.length + 1;
        this.config_list.push(data);
      }
    },
    handleChangeStatus(index) {
      if (this.config_list[index].enable == 1) {
        this.config_list[index].enable = 0;
      } else {
        this.config_list[index].enable = 1;
      }
    },
    handleShowAdd(data, index) {
      if (data.name) {
        this.editData = JSON.parse(JSON.stringify(data));
        this.editIndex = index;
      }
      this.showAddConfig = true;
    },
    handleDelete(index) {
      this.config_list.splice(index, 1);
      this.config_list.forEach((e, i) => {
        this.config_list[i].id = i + 1;
      });
    },
    handleSaveConfig() {
      this.$confirm("是否保存当前设置", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setCycIndexNavs({ config: this.config_list })
            .then((res) => {
              this.$message({
                message: "保存成功",
                type: "success",
              });
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    getIndexConfig() {
      this.loading = true;
      getCycIndexNavs()
        .then((res) => {
          this.config_list = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  .install-top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}
</style>
